<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-card
        tile
        elevation="0"
        :width="this.$vuetify.breakpoint.mdAndUp ? '75%' : '96%'"
        class="text-center justify-center"
      >
        <div v-html="this.title"></div>
        <div v-html="this.body"></div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MissingConfig",

  computed: {
    config() {
      return this.$store.getters["config/config"] || {};
    },

    title() {
      return (
        (this.config &&
          this.config.reservation &&
          this.config.reservation.offline_message &&
          this.config.reservation.offline_message.title) ||
        ""
      );
    },

    body() {
      return (
        (this.config &&
          this.config.reservation &&
          this.config.reservation.offline_message &&
          this.config.reservation.offline_message.body) ||
        ""
      );
    },
  },
};
</script>
